import * as React from "react";
import Layout from "../_components/Layout/Layout";
import CookiesButton from "../_components/CookiesButton/CookiesButton";
import MainTitle from "../_components/MainTitle/MainTitle";
import PolicyDescription from "../_components/PolicyDescription/PolicyDescription";

const Cookies = () => {
    return (
        <Layout
            title="Cookies Policy"
            hideFooterNavigation={true}
            hideCookies={true}
        >
            <MainTitle className="text-center">Cookies Policy</MainTitle>

            <div>
                <PolicyDescription>
                    When you use this website, mobile application, and related third party online services (the “Site”), we may place and store cookies on your web browser and device. Like most providers of online services, Fabacus uses cookies to provide, secure and improve our Site, including by remembering your preferences and recognizing you when you visit our Site. To accomplish this, Fabacus also may link information from cookies with other personal information Fabacus holds about you. This Cookie Policy explains what cookies are, what types of cookies may be on the Site, how Fabacus uses them, and how you can manage your cookies.
                </PolicyDescription>

                <MainTitle secondary>What are cookies and why are they used?</MainTitle>

                <PolicyDescription>
                    Cookies are small text files that are provided by most websites and are sent to or accessed from your web browser or your device’s memory. They are stored by your web browser on the computer, phone, or other device that you are using. Cookies may contain information about your device such as user settings, browsing history and activities conducted while using our Site, and cookies serve many purposes. They can help the Site remember your preferences, learn which areas of the site are useful and which areas need improvement, and provide you with targeted advertisements or personalized content. Sometimes, cookies are enabled when pixels - also referred to as web beacons, clear gifs, or pixel tags- are placed on the Site. They enable the Site to read and place cookies and recognise when someone has opened an email Fabacus has sent them.
                </PolicyDescription>

                <MainTitle secondary>Other related technology</MainTitle>

                <PolicyDescription>
                    Fabacus may also refer to other related technology such as tracking URLs or software development kits (SDKs) as cookies. Tracking URLs are custom generated links that help us understand where the traffic to our Site comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.
                </PolicyDescription>

                <MainTitle secondary>What kinds of cookies are there and what do those cookies do?</MainTitle>

                <PolicyDescription>
                    First-party cookies and third-party cookies: A first-party cookie is one that you receive directly from us when visiting our Site. A third-party cookie is one that you have received from another party. We do not control what third parties do on other sites. However, we may work with certain third-party providers including but not limited to WordPress, Google, YouTube, and other social media parties to permit their cookies to function through our Site so we can learn more about your web experience on our Site and better personalize our services for you.
                    <br />
                    Persistent and session cookies: A persistent cookie is a cookie that is stored by the web browser on your device until it expires or you delete it; it is not automatically deleted when you close your browser. The expiration of a persistent cookie is determined by the cookie creator of the cookie creator; while the cookie exists, its information will be transmitted to the creator’s server every time the user visits the website that it belongs to or another website configured to check for it. Persistent cookies are also called “tracking cookies” and can be used for analytical purposes. Session cookies only last until you close your browser; they are created temporarily on your device for use by the Site while you are visiting it, to store information you may enter and track your activity while on the Site. We use session cookies for various reasons such as to learn more about your use of the Site and to help you use it more efficiently during each visit.
                    <br />
                    Any of the following cookies can be first-party or third-party cookies, and persistent or session cookies:
                    <br />
                    • Essential Site Cookies: These are cookies required to use the Site; they do things like adjust the Site data transmitted to match your Internet connection, allow you to access secure versions of the Site, and help fulfill your specific requests. These cookies do not store personal information. If these cookies are blocked, the Site may not work completely.
                    <br />
                    • Performance Cookies: These cookies count visits and traffic sources to allow us to understand how our Site is being used, and measure and improve Site performance, such as identifying the most popular pages on the site and providing us with information about how Site visitors navigate within the Site. These cookies do not store personal information.
                    <br />
                    • Functional/Analytic and Targeting Cookies: These cookies may store certain personal information that allows the Site to remember choices you have made while visiting and therefore give you personalized features and improved functionality. In addition, Targeting Cookies help us manage and display interest-based advertisements. If you choose to opt out of interest-based advertising, you will no longer see ads from the opt-out advertisers whose content was curated to your interests; however, you may still see online advertising. Among the functional cookies are cookies from Adobe Flash Player™ Flash, an application for viewing and interacting with dynamic content using the Flash platform. Flash uses a technology akin to cookies to memorize parameters, preferences and uses of this content and manages this information and your choices via an interface separate from that supplied by your browser. If your terminal is likely to display content developed using the Flash platform, Fabacus suggests you access your Flash cookie management tools directly via https://www.adobe.com. Among the analytic cookies are those from Google Analytics, which uses cookies and other data collection technologies to collect information about your use of the Site in order to report Site rends. You can opt out of Google Analytics by visiting www.google.com/settings/ads or by downloading the Google Analytics opt-out browser add-on at https://tools.google.com/dlpage/gaoptout.
                    <br />
                    • Social Media Cookies: These third-party cookies are used to allow you to share pages and content from our Site through social media and other third-party sites via social media plug-ins. We may also embed cookie-enabled YouTube videos on our Site. Your options with respect to social media cookies are determined by the social media platforms on which you have an account. To learn more about social media cookies, Fabacus suggests you refer to your social media platform’s cookie notice and privacy notice.
                </PolicyDescription>

                <MainTitle secondary>How to Manage Cookies:</MainTitle>

                <PolicyDescription>
                    Options to accept or delete cookies are browser-specific. Usually, you can manage cookies in the “options”, “tools” or “preferences” menu of the browser you use, or you can visit the browser’s help menu.
                    <br />
                    In addition, online tools such as www.aboutcookies.org or www.allaboutcookies.org provide ways to delete all cookies; deleting all cookies will usually anonymise information associated with a pixel and the Site will not receive any additional information associated with that pixel.
                    <br />
                    We may change this Cookie Policy at any time, and changes will become effective when the revised Cookie Policy is available on or through the Site.
                    <br />
                    If you have any further questions, please contact us by emailing us at <a href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>{process.env.GATSBY_CONTACT_EMAIL}</a>
                </PolicyDescription>
            </div>

            <CookiesButton />
        </Layout>
    );
};

export default Cookies;
